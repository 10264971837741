import styled, { css, device, themeGet } from "@doar/shared/styled";
import { Tabs } from "react-tabs";
import type { TabsProps } from "react-tabs";

interface ITab extends Omit<TabsProps, "className"> {
    $justified?: boolean;
    $vertical?: boolean;
    $variation?: "line";
}

export const StyledSteps = styled(({ ...rest }) => <Tabs {...rest} />)<ITab>`
    ${({ $vertical }) =>
        $vertical === true &&
        css`
            display: flex;
            align-items: flex-start;
        `}
    .react-tabs {
        &__tab-list {
            display: flex;
            flex-wrap: wrap;
            border-color: ${themeGet("colors.border")};
            ${({ $vertical }) =>
                $vertical === true &&
                css`
                    border-bottom: 0;
                    border-right: 0;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 150px;
                `}
            ${({ $variation }) =>
                $variation === "line" &&
                css`
                    border-bottom: 2px solid ${themeGet("colors.border")};
                `}
        }
        &__tab {
            margin-bottom: 0;
            background-color: ${themeGet("colors.border")};
            border-color: ${themeGet("colors.border")};
            color: ${themeGet("colors.gray700")};
            border-bottom-width: 0;
            position: relative;
            margin-bottom: -1px;
            margin-left: 42px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            line-height: 1em;
            transition: background-color 0.2s;
            // border: 1px solid transparent;
            // border-top-left-radius: 0.25rem;
            // border-top-right-radius: 0.25rem;
            cursor: pointer;
            

            ${device.medium} {
                height: 50px;
            }
            ${device.large} {
              
                margin-left: 35px;
                height: 40px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -30px;
                width: 0px;
                border-style: solid;
                border-width: 30px 0 30px 30px;
                ${device.medium} {
                    border-width: 25px 0 25px 25px;
                    left: -25px;
                }
                ${device.large} {
                    border-width: 20px 0 20px 20px;
                    left: -20px;
                }
                border-color: ${themeGet("colors.border")} transparent
                    ${themeGet("colors.border")} transparent;
                // Add transition to the pseudo-element
                transition: border-color 0.2s;
                z-index: 0;
            }
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                right: -60px;
                width: 30px;
                top: 0;
                border-style: solid;
                border-width: 30px 0 30px 30px;
                ${device.medium} {
                    border-width: 25px 0 25px 25px;
                    right: -50px;
                    width: 25px;
                }
                ${device.large} {
                    border-width: 20px 0 20px 20px;
                    right: -40px;
                    width: 20px;
                }
                border-color: transparent transparent transparent
                    ${themeGet("colors.border")};
                transition: border-color 0.2s;
                z-index: 0;
            }
            
            // Add an extra arrow-head before the first tab
            &:first-of-type {
                margin-left: 0;
                &:before {
                    display: none;
                }
            }

            &:last-of-type {
                &:after {
                    display: none;
                }
            }
            &--selected {
                background-color: ${themeGet("colors.primary")};
                z-index: 5;
                color: ${themeGet("colors.white")};
                border-color: ${themeGet("colors.gray300")}
                    ${themeGet("colors.gray300")} #fff;
                &:after {
                border-color: transparent transparent transparent${themeGet(
                    "colors.primary"
                )};
                }
                &:before {
                    border-color: ${themeGet("colors.primary")} transparent
                        ${themeGet("colors.primary")} transparent;
                }
            }

            &--disabled {
                background-color: transparent;
                // color: ${themeGet("colors.gray400")};
                color: ${themeGet("colors.border")}
                border-color: transparent;
                pointer-events: none;
                cursor: default;
            }
            ${({ $justified }) =>
                $justified === true &&
                css`
                    flex-basis: 0;
                    flex-grow: 1;
                    text-align: center;
                `}
            ${({ $vertical }) =>
                $vertical === true &&
                css`
                    border-color: ${themeGet("colors.border")};
                    border-right-width: 0;
                    margin-bottom: 0;
                    margin-right: -1px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 0.25rem;
                    border-bottom-left-radius: 0.25rem;
                    &:not(:first-of-type) {
                        margin-left: 0;
                        margin-top: 5px;
                    }
                    &--selected {
                        border-color: ${themeGet("colors.border")};
                    }
                `}
			${({ $variation }) =>
                $variation === "line" &&
                css`
                    padding: 8px 0;
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    &:not(:first-of-type) {
                        margin-left: 25px;
                    }
                    &--selected {
                        border: none;
                        background-color: transparent;
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 0;
                            height: 2px;
                            background-color: ${themeGet("colors.primary")};
                        }
                    }
                `}
                ${(props) =>
                    props.theme.name === "dark" &&
                    css`
                        color: ${themeGet("colors.gray300")};
                    `}
        }
        &__tab-panel {
            transition: all 0.15s linear;
            display: none;
            height: 0;
            max-width: 100%;
            visibility: hidden;
            overflow: hidden;
            opacity: 0;
            &--selected {
                display: block;
                height: auto;
                visibility: visible;
                opacity: 1;
                overflow: visible;
            }
        }
        &__content {
            ${({ $vertical }) =>
                $vertical === true &&
                css`
                    flex: 1;
                `}
            ${({ $variation }) =>
                $variation === "line" &&
                css`
                    border: none;
                    padding: 0;
                    margin-top: 20px;
                `}
        }
    }
`;

export const StyledStepContent = styled.div`
    padding: 20px;
    border-top: 0;
    border: 1px solid ${themeGet("colors.gray300")};
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            border-color: ${themeGet("colors.gray500")};
        `}
`;
