import {
    Children,
    isValidElement,
    useState,
    useCallback,
    FunctionComponent,
} from "react";
import classnames from "clsx";
import { useClickOutside } from "@doar/shared/hooks";
import { StyledDropdown } from "./style";

interface DropdownProps {
    children: React.ReactNode;
    /**
     * Required. Default is `down`.
     */
    direction: "up" | "down" | "left" | "right";
    type: "basic" | "suggestion";
    className?: string;
}

const Dropdown = ({
    children,
    direction,
    className,
    type,
    ...restProps
}: DropdownProps) => {
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow((prev) => !prev);
    };
    const onClose = useCallback(() => {
        setShow(false);
    }, []);

    const containerRef = useClickOutside<HTMLDivElement>(onClose);
    let RenderedContent = null;
    if (type === "basic") {
        RenderedContent = Children.map(children, (el) => {
            if (!isValidElement(el)) return el;
            const child = el;
            if (child !== null) {
                const childType = child.type as FunctionComponent;
                const name = childType.displayName || childType.name;
                if (name === "DropdownToggle") {
                    return (
                        <child.type {...child.props} onClick={handleClick} />
                    );
                }
                if (name === "DropdownMenu") {
                    return (
                        <child.type
                            {...child.props}
                            direction={direction}
                            show={show}
                            onClick={handleClick}
                        />
                    );
                }
            }
            return null;
        });
    } else if (type === "suggestion") {
        RenderedContent = Children.map(children, (el) => {
            if (!isValidElement(el)) return el;
            const child = el;
            if (child !== null) {
                const childType = child.type as FunctionComponent;
                const name = childType.displayName || childType.name;
                if (name === "DropdownToggle") {
                    return (
                        <child.type {...child.props} onClick={handleClick} />
                    );
                }
                if (name === "DropdownMenu") {
                    return (
                        <child.type
                            {...child.props}
                            direction={direction}
                            show={show}
                            onClick={handleClick}
                        />
                    );
                }
                if (
                    name === "Input" ||
                    name === "Textarea" ||
                    name === "Styled(Component)"
                ) {
                    return (
                        <child.type
                            {...child.props}
                            onClick={() => setShow(true)}
                        />
                    );
                } else {
                    console.log("name", name);
                }
            }
            return null;
        });
    }
    const RenderChild = RenderedContent;

    return (
        <StyledDropdown
            {...restProps}
            className={classnames(className, "dropdown")}
            ref={containerRef}
        >
            {RenderChild}
        </StyledDropdown>
    );
};

Dropdown.defaultProps = {
    direction: "down",
    type: "basic",
};

export default Dropdown;
