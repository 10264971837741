import { createContext, useCallback, useState } from "react";

export const SearchContext = createContext({
    searchOpen: false,
    searchHandler: () => {
        console.log("Not set");
    },
});

const SearchProvider = ({ children }: { children: React.ReactNode }) => {
    const [searchOpen, setSearchOpen] = useState(false);
    const searchHandler = useCallback(() => {
        setSearchOpen((prev) => !prev);
    }, []);
    return (
        <SearchContext.Provider value={{ searchOpen, searchHandler }}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;
