import { PieChart, CheckCircle } from "react-feather";

const asideMenus = [
    {
        id: 1,
        label: "Practice",
        url: "/",
        Icon: PieChart,
        submenu: [
            {
                id: 11,
                label: "Questions",
                url: "/questions",
                Icon: CheckCircle,
            },
        ],
    },
];

export default asideMenus;
