import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import resourcesToBackend from "i18next-resources-to-backend";
import Backend from "i18next-http-backend";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

export const availableLanguages = ["en", "de", "es", "hu"];

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //   .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // .use(
    //     resourcesToBackend(
    //         (language: string, namespace: string) =>
    //             import(`./locales/${language}/${namespace}.json`)
    //     )
    // )
    .use(Backend)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: (lng, ns) =>
                `https://d2lc31oxtreybh.cloudfront.net/locales/${lng}/${ns}.json`,
            crossDomain: true,
            crossOriginIsolated: false,
        },
        fallbackLng: "en",
        supportedLngs: availableLanguages,
        preload: availableLanguages,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
    .then()
    .catch((e) => console.log(e));

export default i18n;
