import classnames from "clsx";
import { StyledFooter, StyledHeader } from "./style";
import { IProps } from "./types";

const ModalHeader = ({ className, children, ...restProps }: IProps) => {
    return (
        <StyledHeader
            className={classnames(className, "modal-body")}
            {...restProps}
        >
            {children}
        </StyledHeader>
    );
};

export default ModalHeader;
