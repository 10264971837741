import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { SuggestionDropdown } from "./index";
import { IRubricResult, SimpleResult } from "@doar/shared/types";

interface DropdownContextValue {
    showDropdown: (
        x: number,
        y: number,
        width: number,
        height: number,
        rubric: IRubricResult,
        content: SimpleResult[],
        onCloseCallback: (
            selectedSuggestion: SimpleResult,
            rubric: IRubricResult
        ) => void
    ) => void;
}

const DropdownContext = createContext<DropdownContextValue | undefined>(
    undefined
);

export const useDropdown = (): DropdownContextValue => {
    const context = useContext(DropdownContext);
    if (!context)
        throw new Error("useDropdown must be used within a DropdownProvider");
    return context;
};

interface DropdownProviderProps {
    children: ReactNode;
}

export const DropdownProvider = ({ children }: DropdownProviderProps) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownRubric, setDropdownRubric] = useState<IRubricResult>({
        db_id: -1,
        rubric_id: -1,
        concept: "",
        max_score: -1,
        marker_type: "CompletionMarker",
        question: "",
        present: false,
        score: -1,
        highlights: [],
        alternative_results: [],
    });
    const [dropdownContent, setDropdownContent] = useState<SimpleResult[]>([]);
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    const [sizes, setSizes] = useState({ width: 0, height: 0 });
    const [callback, setCallback] = useState<
        (selectedSuggestion: SimpleResult, rubric: IRubricResult) => void
    >(() => {});
    const hideDropdown = () => {
        setDropdownVisible(false);
        setDropdownContent([]);
        setDropdownPosition({ x: 0, y: 0 });
        setSizes({ width: 0, height: 0 });
    };
    const showDropdown = (
        x: number,
        y: number,
        wi: number,
        he: number,
        rubric: IRubricResult,
        content: SimpleResult[],
        onCloseCallback: (
            selectedSuggestion: SimpleResult,
            rubric: IRubricResult
        ) => void
    ) => {
        setDropdownPosition({ x, y });
        setSizes({ width: wi, height: he });
        setDropdownContent(content);
        setDropdownVisible(false);
        setDropdownRubric(rubric);
        setCallback(() => onCloseCallback);
    };

    const contextValue: DropdownContextValue = {
        showDropdown,
    };
    useEffect(() => {
        setDropdownVisible(dropdownContent.length > 0 ? true : false);
    }, [dropdownContent]);

    return (
        <DropdownContext.Provider value={contextValue}>
            {children}
            {isDropdownVisible && (
                <SuggestionDropdown
                    x={dropdownPosition.x}
                    y={dropdownPosition.y}
                    width={sizes.width}
                    height={sizes.height}
                    items={dropdownContent}
                    show={isDropdownVisible}
                    rubric={dropdownRubric}
                    callback={(
                        selectedSuggestion: SimpleResult,
                        rubric: IRubricResult
                    ) => {
                        hideDropdown();
                        callback(selectedSuggestion, rubric);
                    }}
                    hideDropdown={hideDropdown}
                />
            )}
        </DropdownContext.Provider>
    );
};
