import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QueryClient } from "react-query";

interface LanguageRouterProps {
    children: React.ReactNode;
    client: QueryClient;
}

const LanguageRouter: React.FC<LanguageRouterProps> = ({
    children,
    client,
}) => {
    const { i18n } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const langParam = searchParams.get("lang");
        const storedLang = client.getQueryData<string>("lang");

        const langToSet = langParam || storedLang;

        if (langToSet && i18n.language !== langToSet) {
            i18n.changeLanguage(langToSet)
                .then(() => {
                    // Store the language in the QueryClient for persistence
                    client.setQueryData("lang", langToSet);
                })
                .catch((error) => {
                    console.error("Failed to change language:", error);
                });
        }
    }, [location, i18n, client]);

    return <>{children}</>;
};

export default LanguageRouter;
