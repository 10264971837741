import { forwardRef } from "react";
import classnames from "clsx";
import { StyledSlider, StyledToggle, StyledToggleLabel } from "./style";
import { StyledBoxWrap } from "../style";
import Feedback from "../feedback";
import { IInputProps } from "../types";

interface IProps extends IInputProps {
    checked?: boolean;
    label: string;
    isEmbedded: boolean;
    size?: "xs" | "sm" | "md" | "lg";
}

const getSize = (size?: "xs" | "sm" | "md" | "lg") => {
    switch (size) {
        case "xs":
            return "0.6em";
        case "sm":
            return "0.75em";
        case "md":
            return "1em";
        case "lg":
            return "1.15em";
        default:
            return "1em";
    }
};

const Toggle = forwardRef<HTMLInputElement, IProps>(
    (
        {
            className,
            disabled,
            feedbackText,
            id,
            name,
            label,
            size,
            state,
            checked,
            onChange,
            onClick,
            onBlur,
            value,
            isEmbedded,
            ...restProps
        },
        ref
    ) => {
        return (
            <StyledBoxWrap
                style={{
                    display: "inline-flex",
                    fontSize: getSize(size),
                    verticalAlign: "middle",
                }}
                className={classnames(className, "custom-toggle")}
                {...restProps}
            >
                <StyledToggle
                    type="checkbox"
                    disabled={disabled}
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={onChange ? onChange : () => {}}
                    onClick={onClick}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                />
                <StyledSlider $size={size} onClick={onClick} />
                <StyledToggleLabel
                    htmlFor={id}
                    $size={size}
                    style={{ color: isEmbedded ? "#fff" : "" }}
                >
                    {label}
                </StyledToggleLabel>
                {feedbackText && (
                    <Feedback state={state}>{feedbackText}</Feedback>
                )}
            </StyledBoxWrap>
        );
    }
);

Toggle.displayName = "Toggle";

export default Toggle;
