import classnames from "clsx";
import { StyledDropItem } from "./style";

interface IDropItem {
    children: React.ReactNode;
    onClick: (e: React.MouseEvent) => void;
    className?: string;
    active?: boolean;
}

const SuggestionItem = ({
    children,
    onClick,
    className,
    active,
}: IDropItem) => (
    <StyledDropItem
        path="#"
        target="self"
        active={active}
        onClick={onClick}
        className={classnames(className, "dropdown-item")}
    >
        {children}
    </StyledDropItem>
);

export default SuggestionItem;
