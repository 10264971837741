import { TypographyProps } from "@doar/shared/styled";
import { StyledTitle, StyledDesc } from "./style";

interface IProps {
    title: string;
    desc?: string;
    descProps?: TypographyProps;
    titleProps?: TypographyProps;
    filterString?: string;
}

const SectionTitle = ({
    title,
    desc,
    descProps,
    titleProps,
    filterString,
}: IProps) => {
    const sections = filterString
        ? title
              .split(
                  new RegExp(
                      filterString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                      "i"
                  )
              )
              .map((section, index, sections) => ({
                  text: section,
                  end:
                      sections
                          .filter((s, i) => i < index)
                          .reduce(
                              (sum, sec) =>
                                  sum + sec.length + filterString.length,
                              0
                          ) + section.length,
              }))
        : [{ text: title, end: title.length }];

    return (
        <>
            <StyledTitle $hasDesc={Boolean(desc)} {...titleProps}>
                {sections.map((section, index) => {
                    return (
                        <span key={index}>
                            {section.text}
                            {index < sections.length - 1 && filterString && (
                                <mark>
                                    {title.slice(
                                        section.end,
                                        section.end + filterString.length
                                    )}
                                </mark>
                            )}
                        </span>
                    );
                })}
            </StyledTitle>
            {desc && (
                <StyledDesc
                    {...descProps}
                    dangerouslySetInnerHTML={{ __html: desc }}
                />
            )}
        </>
    );
};

export default SectionTitle;
