import { themeGet } from "@doar/shared/styled";
import Plot from "react-plotly.js";

interface IProps {
    data: Plotly.Data[];
    layout?: Plotly.Layout;
    width: number;
    height: number;
}

const PlotlyChart = ({ data, layout, width, height }: IProps) => (
    <Plot
        data={data}
        layout={{
            ...layout,
            font: {
                family: "Inter UI",
                size: 15,
            },
            // width: width,
            // height: height,
            autosize: true,
            title: "",
            margin: { r: 0, t: 0 },
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
            hovermode: "closest",
            hoverlabel: {
                // Make sure the hover label has bg color
                bgcolor: "#FFF",
                bordercolor: "#FFF",
                font: {
                    color: "#000",
                    size: 15,
                    family: "Inter UI",
                },
                // Text should be [x-axistitle] = [x-value] and [y-axistitle] = [y-value]
            },
        }}
        config={{ displayModeBar: false }}
    />
);

export default PlotlyChart;
