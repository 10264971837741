import { useState, useEffect, useRef, useMemo } from "react";
import classnames from "clsx";
import { StyledDropMenu } from "./style";

type TDirection = "up" | "down" | "left" | "right";

interface IDropMenu {
    children: React.ReactNode;
    show?: boolean;
    direction?: TDirection;
    className?: string;
    inputHeight?: number;
}

interface IMenuMeasure {
    clientWidth: number;
    clientHeight: number;
    clientLeft: number;
    clientTop: number;
    offsetWidth: number;
    offsetHeight: number;
    offsetLeft: number;
    offsetTop: number;
}

const DropdownMenu = ({
    children,
    show,
    direction,
    inputHeight,
    className,
    ...restProps
}: IDropMenu) => {
    const [menuMeasure, setMenuMeasure] = useState<IMenuMeasure>({
        clientWidth: 0,
        clientHeight: 0,
        clientLeft: 0,
        clientTop: 0,
        offsetWidth: 0,
        offsetHeight: 0,
        offsetLeft: 0,
        offsetTop: 0,
    });
    const menuRef: React.Ref<HTMLDivElement> = useRef(null);

    const [directionIsCalculated, setDirectionIsCalculated] = useState(false);

    useEffect(() => {
        setMenuMeasure((prev) => {
            if (!menuRef?.current) return prev;
            const rect = menuRef.current.getBoundingClientRect();
            return {
                ...prev,
                clientWidth: menuRef?.current?.clientWidth || 0,
                clientHeight: rect.height || 0,
                clientLeft: menuRef?.current?.clientLeft || 0,
                clientTop: rect.top || 0,
                offsetWidth: menuRef?.current?.offsetWidth || 0,
                offsetHeight: menuRef?.current?.offsetHeight || 0,
                offsetLeft: menuRef?.current?.offsetLeft || 0,
                offsetTop: rect.top || 0,
            };
        });
        setDirectionIsCalculated(false);
    }, [show, menuRef]);

    // Compute position of menu
    // And reverse direction if menu is out of viewport
    const menuDirection = useMemo(() => {
        const { offsetWidth, offsetHeight } = menuMeasure;
        const { innerWidth, innerHeight } = window;
        const { offsetLeft, offsetTop } = menuMeasure;
        const isUp = direction === "up";
        const isDown = direction === "down";
        const isLeft = direction === "left";
        const isRight = direction === "right";

        if (!show) return { direction: "down" };
        // If direction is up or down and is out of viewport
        setDirectionIsCalculated(true);
        if ((isUp || isDown) && offsetTop + offsetHeight > innerHeight) {
            return { direction: isUp ? "down" : "up" };
        }
        // If direction is left or right and is out of viewport
        if ((isLeft || isRight) && offsetLeft + offsetWidth > innerWidth) {
            return { direction: isLeft ? "right" : "left" };
        }

        return { direction: direction || "down" };
    }, [menuMeasure, direction, show]);

    return (
        <div
            // role="presentation"
            style={{
                position: "absolute",
                alignContent: "right",
                alignItems: "right",
                display: directionIsCalculated && show ? "block" : "none",
                width: "90%",
            }}
        >
            <StyledDropMenu
                $menuWidth={menuMeasure.offsetWidth}
                $show={directionIsCalculated && show}
                $direction={menuDirection.direction as TDirection}
                $inputHeight={inputHeight || 0}
                ref={menuRef}
                className={classnames(className, "dropdown-menu")}
                {...restProps}
            >
                {children}
            </StyledDropMenu>
        </div>
    );
};

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
