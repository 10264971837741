/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { themeGet, tinycolor, css } from "@doar/shared/styled";
import check from "../../../static/check.svg";

interface IProps {
    $size?: "xs" | "sm" | "md" | "lg";
}

export const StyledToggleLabel = styled.label<IProps>`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    margin-bottom: 0;
    vertical-align: center;
    line-height: 1.5em;
    cursor: pointer;
    padding-left: 2.5em;

    ${({ $size }) =>
        $size === "xs" &&
        css`
            padding-left: 0.8em;
        `}
    ${({ $size }) =>
        $size === "sm" &&
        css`
            padding-left: 1.3em;
        `}
    ${({ $size }) =>
        $size === "md" &&
        css`
            padding-left: 1.8em;
        `}
    ${({ $size }) =>
        $size === "lg" &&
        css`
            padding-left: 2em;
        `}

    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 600;
    color: ${themeGet("colors.gray500")};
`;

export const StyledSlider = styled.span<IProps>`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${themeGet("colors.gray200")};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    // border: 1px solid ${themeGet("colors.gray300")};
    border-radius: 3em;

    box-shadow: inset 0 0 3px ${themeGet("colors.gray400")};
    width: 2.5em;

    &:before {
        position: absolute;
        content: "";
        height: 0.9em;
        width: 0.9em;
        left: 0.25em;
        bottom: 0.25em;
        background-color: white;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        border-radius: 50%;
    }
`;

export const StyledToggle = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
        & ~ ${StyledSlider} {
            background-color: ${themeGet("colors.primary")};
            box-shadow: inset 0 0 4px ${themeGet("colors.gray600")};
        }
        & ~ ${StyledToggleLabel} {
            color: ${themeGet("colors.primary")};
        }
        & ~ ${StyledSlider}:before {
            -webkit-transform: translateX(1.1em);
            -ms-transform: translateX(1.1em);
            transform: translateX(1.1em);
        }
    }
    // &:focus {
    //     & ~ ${StyledSlider} {
    //         box-shadow: 0 0 3px ${themeGet("colors.gray600")};
    //     }
    // }
    &:disabled {
        & ~ ${StyledSlider} {
            background-color: ${themeGet("colors.gray300")};
        }
        & ~ ${StyledSlider}:before {
            background-color: ${themeGet("colors.gray500")};
        }
    }
`;
