import {
    useState,
    useEffect,
    useRef,
    Children,
    isValidElement,
    FunctionComponent,
} from "react";
import classnames from "clsx";
import { StyledDropMenu } from "./style";

interface IDropMenu {
    children: React.ReactNode;
    show?: boolean;
    direction?: "up" | "down" | "left" | "right";
    justify?: "start" | "end" | "center";
    className?: string;
    hideMenu?: () => void;
}

interface IMenuMeasure {
    clientWidth: number;
    clientHeight: number;
    clientLeft: number;
    clientTop: number;
    offsetWidth: number;
    offsetHeight: number;
    offsetLeft: number;
    offsetTop: number;
}

const DropdownMenu = ({
    children,
    show,
    direction,
    justify,
    className,
    hideMenu,
    ...restProps
}: IDropMenu) => {
    const [menuMeasure, setMenuMeasure] = useState<IMenuMeasure>({
        clientWidth: 0,
        clientHeight: 0,
        clientLeft: 0,
        clientTop: 0,
        offsetWidth: 0,
        offsetHeight: 0,
        offsetLeft: 0,
        offsetTop: 0,
    });
    const menuRef: React.Ref<HTMLDivElement> = useRef(null);

    useEffect(() => {
        setMenuMeasure((prev) => {
            return {
                ...prev,
                clientWidth: menuRef?.current?.clientWidth || 0,
                clientHeight: menuRef?.current?.clientHeight || 0,
                clientLeft: menuRef?.current?.clientLeft || 0,
                clientTop: menuRef?.current?.clientTop || 0,
                offsetWidth: menuRef?.current?.offsetWidth || 0,
                offsetHeight: menuRef?.current?.offsetHeight || 0,
                offsetLeft: menuRef?.current?.offsetLeft || 0,
                offsetTop: menuRef?.current?.offsetTop || 0,
            };
        });
    }, [show]);

    const RenderChild = Children.map(children, (el) => {
        if (!isValidElement(el)) return el;
        const child = el;
        if (child !== null) {
            const childType = child.type as FunctionComponent;
            const name = childType.displayName || childType.name;
            if (name === "MarkerTypeOption") {
                return <child.type {...child.props} hideMenu={hideMenu} />;
            }

            return <child.type {...child.props} />;
        }
    });

    return (
        <StyledDropMenu
            $menuWidth={menuMeasure.offsetWidth}
            $show={show}
            $direction={direction}
            $justify={justify}
            ref={menuRef}
            className={classnames(className, "dropdown-menu")}
            {...restProps}
        >
            {RenderChild}
        </StyledDropMenu>
    );
};

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
